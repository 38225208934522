import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { useMixpanel } from "../../context/MixpanelContext";
import { StyledGatsbyLink } from "../../components/core/commonExports";

export const Footer = ({style={}}) => {
  const mixpanel = useMixpanel();

  const sendMixpanelEvent = (mxEventName) => {
    mixpanel.track(mxEventName);
  };

  return (
    <div className="footer-wrapper" style={style}>
      <div
        className="wrapper"
        style={{ marginTop: "80px", position: "relative" }}
      >
        <div className="footer-main-container">
          <div className="section-container" style={{ alignItems: "start" }}>
            <div className="left-section">
              <h4 className={`footer-head-text`}>
                Girnar Insurance Brokers Private Limited
              </h4>
              <div className="company-section-container">
                <p
                  className={`footer-normal-text`}
                  style={{ marginBottom: "10px" }}
                >
                    IRDAI License no. 588 <br />
                    Direct Broker (Life & General) <br />
                    Valid upto 19/03/2023
                </p>
              </div>
              <div className="on-mobile">
                <h4 className={`footer-head-text`}>Find us on :</h4>
                <div className="flex-div" style={{ marginTop: "10px" }}>
                  <a
                    className="social-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.linkedin.com/company/verak"
                  >
                    <StaticImage
                      alt="linkedin"
                      src="../../assets/images/linkedin_small.png"
                      placeholder="#FFF7F1"
                      loading="lazy"
                    />
                  </a>

                  <a
                    className="social-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://twitter.com/VerakInsurance"
                  >
                    <StaticImage
                      alt="twitter"
                      src="../../assets/images/twitter_small.png"
                      placeholder="#FFF7F1"
                      loading="lazy"
                      height="26px"
                    />
                  </a>

                  <a
                    className="social-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.facebook.com/VerakInsurance"
                  >
                    <StaticImage
                      alt="facebook"
                      src="../../assets/images/facebook_small.png"
                      placeholder="#FFF7F1"
                      loading="lazy"
                      height="26px"
                    />
                  </a>
                  <a
                    className="social-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://wa.me/919769161630?text=Hey"
                  >
                    <StaticImage
                      alt="whatsapp"
                      src="../../assets/images/whatsapp_small.png"
                      placeholder="#FFF7F1"
                      loading="lazy"
                      height="26px"
                    />
                  </a>
                  <a
                    className="social-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://bit.ly/VerakWebYT"
                  >
                    <StaticImage
                      alt="youtube"
                      src="../../assets/images/youtube_small.png"
                      placeholder="#FFF7F1"
                      loading="lazy"
                      height="26px"
                    />
                  </a>
                  <a
                    className="social-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.instagram.com/verakinsurance/"
                  >
                    <StaticImage
                      alt="instagram"
                      src="../../assets/images/instagram_small.png"
                      placeholder="#FFF7F1"
                      loading="lazy"
                      height="26px"
                    />
                  </a>
                </div>

                <h4 className={`footer-head-text`} style={{ marginTop: "10px" }}>
                    IRDAI Certificate :
                </h4>

                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://verak-assets.s3.ap-south-1.amazonaws.com/Girnar+IRDAI+License.pdf"
                >
                  <div
                    className="flex-div"
                    style={{ alignItems: "flex-end", marginTop: "10px" }}
                  >
                    <StaticImage
                      style={{ cursor: "pointer" }}
                      alt="irdai certificate"
                      src="../../assets/images/irdai_certificate.png"
                      placeholder="#FFF7F1"
                      loading="lazy"
                    />
                    <StaticImage
                      style={{ marginLeft: "-1.4rem" }}
                      placeholder="#000"
                      src="../../assets/images/download_icon.png"
                      alt=""
                    />
                  </div>
                </a>
              </div>
            </div>

            <div>
              <div className="on-desktop">
                <div style={{ marginTop: "28px" }}></div>
              </div>
              <p className={`footer-head-text`} style={{ marginBottom: "10px" }}>
                Contact Us:
              </p>
              <div className="on-mobile">
                <p
                  className={`footer-normal-text`}
                  style={{ marginBottom: "5px", fontWeight: "bold" }}
                >
                  Email:
                </p>
              </div>
              <div
                className="flex-div"
                style={{ alignItems: "center", marginBottom: "10px" }}
              >
                <div className="on-desktop">
                  <img
                    alt="email"
                    src="https://verak-assets.s3.ap-south-1.amazonaws.com/mailIcon.svg"
                    style={{ marginRight: "10px" }}
                  />
                </div>
                <p className={`footer-normal-text`}>
                  <a href="mailto:admin@verak.in">admin@verak.in</a>
                </p>
              </div>
              <div className="on-mobile">
                <p
                  className={`footer-normal-text`}
                  style={{
                    marginTop: "5px",
                    marginBottom: "5px",
                    fontWeight: "bold",
                  }}
                >
                  Address:
                </p>
              </div>
              <div className="flex-div" style={{ alignItems: "start" }}>
                <div className="on-desktop">
                  <img
                    alt="address"
                    src="https://verak-assets.s3.ap-south-1.amazonaws.com/locationIcon.svg"
                    style={{ marginRight: "10px" }}
                  />
                </div>
                <p className={`footer-normal-text`}>
                    21 GIRNAR,
                    <br />
                    Govind Marg, Moti Doongri Road, 
                    <br />
                    Dharam Singh Circle, Jaipur 
                    <br />
                    Rajasthan, India, 302004
                </p>
              </div>
              <div className="on-mobile">
                <p
                  className="footer-normal-text"
                  style={{
                    marginTop: "10px",
                    marginBottom: "5px",
                    fontWeight: "bold",
                  }}
                >
                  Phone:
                </p>
              </div>
              <div className="flex-div" style={{ alignItems: "center", marginTop: "5px" }}>
                <div className="on-desktop">
                  <StaticImage
                    alt="phone-icon"
                    src="../../assets/images/phone_small.png"
                    loading="lazy"
                    style={{ marginRight: "5px" }}
                  />
                </div>
                <p className={`footer-normal-text`}>
                  <a href="tel:+919076277062">+91 9076277062</a>
                </p>
              </div>
            </div>
          </div>
          <div className="company-main-section">
            <h4 className={`footer-head-text`}>Resources</h4>
            <div
              className="company-section-container"
              style={{
                justifyContent: "start",
                width: "100%",
                color: "#000",
                fontSize: "12px",
              }}
            >
              <StyledGatsbyLink
                to="/claims"
                onClick={() => sendMixpanelEvent("Clicked 'Claims' - Footer")}
              >
                Claims
              </StyledGatsbyLink>
              <StyledGatsbyLink
                to="/endorsements"
                onClick={() => sendMixpanelEvent("Clicked 'Endorsements' - Footer")}
              >
                Endorsements
              </StyledGatsbyLink>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://insights.verak.in/"
                onClick={() =>
                  sendMixpanelEvent("Clicked 'Verak Insights' - Footer")
                }
              >
                Verak Insights
              </a>
              <a
                href="https://blog.verak.in/"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                  sendMixpanelEvent("Clicked 'Verak Explainers' - Footer")
                }
              >
                Verak Explainers
              </a>
              <a
                href="https://client.verak.in/"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                  sendMixpanelEvent("Clicked 'Client Portal' - Footer")
                }
              >
                Client Login
              </a>
            </div>
          </div>
          <div className="company-main-section">
            <h4 className={`footer-head-text`}>Products</h4>
            <div className="company-section-container">
              <StyledGatsbyLink to="/fire" className={`footer-subheader-text`} onClick={() => sendMixpanelEvent("Clicked  'Fire & Allied Perils' - Footer")}>Business Property Insurance</StyledGatsbyLink>
            </div>
            <div
              className="company-section-container"
              style={{
                justifyContent: "start",
                width: "100%",
                color: "#000",
                fontSize: "12px",
                paddingTop: '0'
              }}
            >
              <StyledGatsbyLink
                to="/business-type/shop"
                onClick={() =>
                  sendMixpanelEvent("Clicked 'Shop' - Footer")
                }
              >
                Shop
              </StyledGatsbyLink>
              <StyledGatsbyLink
                to="/business-type/restaurant"
                onClick={() =>
                  sendMixpanelEvent("Clicked 'Restaurant' - Footer")
                }
              >
                Restaurant
              </StyledGatsbyLink>
              <StyledGatsbyLink
                to="/business-type/factory"
                onClick={() =>
                  sendMixpanelEvent("Clicked 'Factory' - Footer")
                }
              >
                Factory
              </StyledGatsbyLink>
              <StyledGatsbyLink
                to="/business-type/storage"
                onClick={() =>
                  sendMixpanelEvent("Clicked 'Storage' - Footer")
                }
              >
                Storage
              </StyledGatsbyLink>
              <StyledGatsbyLink
                to="/business-type/service-business"
                onClick={() =>
                  sendMixpanelEvent("Clicked 'Service' - Footer")
                }
              >
                Service Business
              </StyledGatsbyLink>
            </div>
            <div className="company-section-container" style={{paddingTop: "6px"}}>
              <StyledGatsbyLink
                to="/liability"
                onClick={() =>
                  sendMixpanelEvent("Clicked 'Liability Insurance' - Footer")
                }
                className={`footer-subheader-text`}
              >
                Liability Insurance
              </StyledGatsbyLink>
            </div>
            <div className="company-section-container" style={{paddingTop: "6px"}}>
              <StyledGatsbyLink
                to="/safety-plan"
                onClick={() =>
                  sendMixpanelEvent("Clicked 'Safety Plan' - Footer")
                }
                className={`footer-subheader-text`}
              >
                Safety Plan
              </StyledGatsbyLink>
            </div>
          </div>
          <div className="company-main-section">
            <h4 className="footer-head-text">Company</h4>
            <div
              className="company-section-container"
              style={{
                justifyContent: "start",
                width: "100%",
                color: "#000",
                fontSize: "12px",
              }}
            >
             <StyledGatsbyLink
                to="/our-company"
                onClick={() => sendMixpanelEvent("Clicked 'Company' - Footer")}
              >
                Company
              </StyledGatsbyLink>
              <StyledGatsbyLink
                to="/our-team"
                onClick={() => sendMixpanelEvent("Clicked 'Team' - Footer")}
              >
                Team
              </StyledGatsbyLink>
              <StyledGatsbyLink
                to="/our-customers"
                onClick={() => sendMixpanelEvent("Clicked 'Customers' - Footer")}
              >
                Customers
              </StyledGatsbyLink>
              <StyledGatsbyLink
                to="/privacy"
                onClick={() =>
                  sendMixpanelEvent("Clicked 'Privacy Policy' - Footer")
                }
              >
                Privacy Policy
              </StyledGatsbyLink>
              {/* <StyledGatsbyLink
                to="/refund-policy"
                onClick={() =>
                  sendMixpanelEvent("Clicked 'Refund Policy' - Footer")
                }
              >
                Refund Policy
              </StyledGatsbyLink> */}
              <StyledGatsbyLink
                to="/terms"
                onClick={() =>
                  sendMixpanelEvent("Clicked 'Terms & Conditions' - Footer")
                }
              >
                Terms & Conditions
              </StyledGatsbyLink>
            </div>
          </div>
          <div className="company-main-section on-desktop">
            <h4 className={`footer-head-text`}>Find us on :</h4>

            <div className="flex-div" style={{ marginTop: "10px" }}>
              <a
                className="social-link"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/company/verak"
              >
                <StaticImage
                  alt="linkedin"
                  src="../../assets/images/linkedin_small.png"
                  placeholder="#FFF7F1"
                  loading="lazy"
                  height="20px"
                />
              </a>

              <a
                className="social-link"
                target="_blank"
                rel="noopener noreferrer"
                href="https://twitter.com/VerakInsurance"
              >
                <StaticImage
                  alt="twitter"
                  src="../../assets/images/twitter_small.png"
                  placeholder="#FFF7F1"
                  loading="lazy"
                  height="26px"
                />
              </a>

              <a
                className="social-link"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/VerakInsurance"
              >
                <StaticImage
                  alt="facebook"
                  src="../../assets/images/facebook_small.png"
                  placeholder="#FFF7F1"
                  loading="lazy"
                  height="26px"
                />
              </a>
              <a
                className="social-link"
                target="_blank"
                rel="noopener noreferrer"
                href="https://wa.me/919769161630?text=Hey"
              >
                <StaticImage
                  alt="whatsapp"
                  src="../../assets/images/whatsapp_small.png"
                  placeholder="#FFF7F1"
                  loading="lazy"
                  height="20px"
                />
              </a>

              <a
                className="social-link"
                target="_blank"
                rel="noopener noreferrer"
                href="https://bit.ly/VerakWebYT"
              >
                <StaticImage
                  alt="youtube"
                  src="../../assets/images/youtube_small.png"
                  placeholder="#FFF7F1"
                  loading="lazy"
                  height="26px"
                />
              </a>

              <a
                className="social-link"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/verakinsurance/"
              >
                <StaticImage
                  alt="instagram"
                  src="../../assets/images/instagram_small.png"
                  placeholder="#FFF7F1"
                  loading="lazy"
                  height="26px"
                />
              </a>
            </div>

            <h4 className={`footer-head-text`} style={{ marginTop: "28px" }}>
                IRDAI Certificate :
            </h4>

            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://verak-assets.s3.ap-south-1.amazonaws.com/Girnar+IRDAI+License.pdf"
            >
              <div
                className="flex-div"
                style={{ alignItems: "flex-end", marginTop: "10px" }}
              >
                <StaticImage
                  alt="irdai ceritificate"
                  src="../../assets/images/irdai_certificate.png"
                  style={{ cursor: "pointer", height: "120px", width: "120px" }}
                  placeholder="#FFF7F1"
                  loading="lazy"
                />
                <StaticImage
                  style={{ marginLeft: "-1.4rem" }}
                  placeholder="#000"
                  src="../../assets/images/download_icon.png"
                  alt=""
                />
              </div>
            </a>
          </div>
        </div>

        <div
          className="section section-border"
          style={{ padding: "30px 0px 60px 10px" }}
        >
          <ol className="disclaimer">
            <li>
                Insurance is a subject matter of solicitation. All insurance products are offered through Girnar Insurance Brokers Private Limited (IRDAI License no. 588)
            </li>
            <li>
                Information presented on this website is for educational purposes only and should not be treated as legal, financial or any other form of advice. No party shall  be held  liable for financial or other any other form of loss incurred by the user or any affiliated party on the basis of information provided herein.
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};
